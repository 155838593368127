<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.projectType.themeSetting.title') }}
          -
          {{ $t('settings.project.projectType.themeSetting.direct.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
      </b-col>
      <b-col cols="12" sm="7">
        <p>{{ $t('settings.project.projectType.themeSetting.direct.desc') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'ThemeSettingsDirect',
  props: {
    settingValues: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.$emit('update-theme-settings', {});
  },
};
</script>

<style lang="scss" scoped>

</style>
