var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.projectType.themeSetting.title")
                  ) +
                  " - " +
                  _vm._s(
                    _vm.$t(
                      "settings.project.projectType.themeSetting.direct.name"
                    )
                  ) +
                  " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }),
          _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "settings.project.projectType.themeSetting.direct.desc"
                  )
                )
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }